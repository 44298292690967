<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Employees"
              :data-source="dataSource"
              :total-count="totalCount"
              :canAdd="getRole"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadUsers()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in dataColumns"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
      no-close-on-backdrop
    >
      <validation-observer ref="userForm">
        <b-row>
          <b-col cols="12" md="6" v-if="imagePreview">
            <b-form-group label="Preview">
              <b-img center thumbnail fluid :src="imagePreview" />
            </b-form-group>
          </b-col>

          <b-col cols="12" :md="imagePreview ? '6' : '12'">
            <b-row>
              <b-col>
                <b-form-group label="Image">
                  <b-form-file
                    ref="imageRef"
                    accept="image/*"
                    v-model="imageUpload"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="First Name" label-for="v-name">
                  <b-form-input v-model="formData.first_name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Last Name" label-for="v-last_name">
                  <b-form-input v-model="formData.last_name"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="User Type" label-for="v-roles">
                  <v-select
                    v-model="formData.roles"
                    multiple
                    label="title"
                    :options="optionRoles"
                    :reduce="(optionRoles) => optionRoles.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Designation" label-for="v-desigation">
              <v-select
                id="v-designation"
                v-model="formData.designation"
                :options="designations"
                :reduce="(designation) => designation.value"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Departments" label-for="v-department">
              <v-select
                id="v-department"
                v-model="formData.department"
                :options="departments"
                :reduce="(department) => department.value"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="formData.email"
                  :state="errors.length > 0 ? false : null"
                  type="email"
                  placeholder="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Street Address" label-for="v-address">
              <b-form-input v-model="formData.street"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="City" label-for="v-city">
              <b-form-input v-model="formData.city"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Country" label-for="v-country">
              <v-select
                v-model="formData.country"
                :options="$store.getters['hbaseGeneral/getAllCountries']"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Postal" label-for="v-postal">
              <b-form-input v-model="formData.zip_code"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" :md="isEditing ? 12 : 4">
            <b-form-group label="Username" label-for="v-username">
              <b-form-input :disabled="isEditing" v-model="formData.username" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" v-if="!isEditing">
            <b-form-group label-for="account-password" label="Password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="!isEditing">
            <b-form-group
              label-for="account-retype-password"
              label="Retype Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-retype-password"
                    v-model="retypePassword"
                    :type="passwordFieldTypeRetype"
                    :state="errors.length > 0 ? false : null"
                    name="retype-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="isEditing">
          <b-col>
            <b-form-group>
              <b-button
                size="sm"
                variant="info"
                class="bg-darken-4"
                @click="resetPasswordModal = true"
              >
                Reset Password
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          :disabled="!password && !retypePassword && !formData.id"
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="resetPasswordModal"
      centered
      @hide="resetPasswordModal = false"
      size="sm"
      no-close-on-backdrop
    >
      <validation-observer ref="resetPasswordRef">
        <b-row>
          <b-col cols="12">
            <b-form-group label-for="account-resetPassword" label="Password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-resetPassword"
                    v-model="resetPassword"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-for="account-retypeResetPassword-password"
              label="Retype Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="account-retypeResetPassword-password"
                    v-model="retypeResetPassword"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="retype-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="resetPasswordModal = false"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onResetPassword()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import userApi from '@api/users';
import designationsApi from '@api/designations';
import departmentsApi from '@api/departments';
import { getImage } from '@/global-functions';
import { required, password } from '@validations';
import md5 from 'md5';
import { queryParameters } from '@/schema';

import { getUserData } from '@/auth/utils';

export default {
  name: 'UserPage',
  components: {},
  data: () => ({
    required,
    password,
    modalShow: false,
    resetPasswordModal: false,
    dataSource: [],
    departments: [],
    designations: [],
    imageUpload: null,
    imagePreview: null,
    imageName: null,
    imageExt: null,
    optionRoles: [{ value: 'qcsemployee', title: 'QCS Employee' }],
    formData: {
      id: 0,
      first_name: '',
      last_name: '',
      designation: null,
      department: null,
      roles: ['qcsemployee'],
      address: {},
      street: null,
      city: null,
      zip_code: null,
      country: 'Singapore',
      username: '',
      password: '',
      confirm_password: '',
      thumbnail: '',
      thumbnailbase64: '',
    },
    password: '',
    retypePassword: '',
    passwordFieldType: 'password',
    passwordFieldTypeRetype: 'password',
    resetPassword: '',
    retypeResetPassword: '',
    oldPassword: '',
    dataColumns: [
      { caption: 'Username', field: 'username' },
      { caption: 'Name', field: 'display_name' },
      { caption: 'Designation', field: 'designation' },
      { caption: 'Department', field: 'department' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
  }),
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon';
    },
    isEditing() {
      return this.formData.id > 0;
    },
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        return role === 'admin';
      },
    },
  },
  watch: {
    imageUpload(e) {
      if (e) {
        const reader = new window.FileReader();
        reader.readAsDataURL(e);
        reader.onload = () => {
          this.imageExt = e.name.split('.')[1];
          this.imageName = e.name;
          this.imagePreview = reader.result;

          this.formData.thumbnail = e.name;
          this.formData.thumbnailbase64 = reader.result;
        };
      }
    },
    page(v) {
      this.loadUsers();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadUsers();
      }
    },
    resetPasswordModal(e) {
      return e;
    },
  },
  mounted() {
    this.loadDepartments();
    this.loadDesignations();
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `roles=qcsemployee`,
      };

      userApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadDesignations() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000, after: 1 },
        status: queryParameters.status,
      };

      designationsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ label: item.description, value: item.name, item });
          }

          this.designations = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadDepartments() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000, after: 1 },
        status: queryParameters.status,
      };

      departmentsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ label: item.description, value: item.name, item });
          }

          this.departments = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      if (this.password != '') {
        this.formData.password = md5(this.password);
      }

      this.formData.address = {
        street: this.formData.street,
        city: this.formData.city,
        zip_code: this.formData.zip_code,
      };

      let app;

      if (this.formData.id) {
        app = userApi.update(this.formData);
      } else {
        app = userApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.closeModal();
            this.loadUsers();
            this.toastConfig();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onResetPassword() {
      this.$refs.resetPasswordRef.validate().then((success) => {
        if (success) {
          const formData = {
            id: this.formData.id,
            username: this.formData.username,
            old_pass: md5(this.oldPassword),
            password: md5(this.resetPassword),
          };

          userApi
            .changePassword(formData)
            .then(({ data }) => {
              this.resetPasswordModal = false;
              this.toastConfig();
            })
            .catch((err) => {
              const msg = this.messageError(err);
              this.toastConfig(msg, 'error');
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      data.street = data.address.street;
      data.city = data.address.city;
      data.zip_code = data.address.zip_code;

      const getImageData = {
        model: 'users',
        id: data.id,
        thumbnail: data.thumbnail,
      };

      data.url = getImage(getImageData);

      if (data.thumbnailbase64 !== '') {
        this.imagePreview = data.thumbnailbase64;
      } else {
        this.imagePreview = data.url;
      }

      this.formData = data;
    },
    onDelete(event) {
      userApi
        .delete(event)
        .then(() => {
          this.loadUsers();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      this.password = '';
      this.retypePassword = '';

      const defaultForm = {
        id: 0,
        first_name: '',
        last_name: '',
        roles: [],
        address: {},
        street: null,
        city: null,
        zip_code: null,
        country: 'Singapore',
        username: '',
        password: '',
        confirm_password: '',
        thumbnail: '',
        thumbnailbase64: '',
        status: 'O',
      };

      this.$nextTick(() => {
        this.imagePreview = '';
        this.formData = Object.assign({}, defaultForm);
      });
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === 'password' ? 'text' : 'password';
    },
  },
};
</script>

<style></style>
